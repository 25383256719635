import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import Admin from './Admin';
import AdminLogin from './AdminLogin';
import RSVPForm from './RSVPForm';
import './App.css';
import weddingImage from "./Wedding-calligraphy.png";
import logo from "./Logo.png";

const Home = () => (
  <>
    <div className='corner-logo'>
      <img src={logo} alt="logo" className="logo" />
    </div>
  
    <img src={weddingImage} alt="wedding-calligraphy" className="wedding-image" />
    <h1 className="title">WEDDING RECEPTION</h1>
    <h1 className="names">of Nasha and Abdul Mannan</h1>
    <blockquote className="wedding-quote">
      “And of His signs is that He created for you from yourselves mates that you may find tranquillity in them, and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.”
      (Surah Ar-Rum, 30:21)
    </blockquote>
    <div className="rsvp-section">
      <h2 className="rsvp-title">RSVP</h2>
      <p className="rsvp-instructions">
        This RSVP form is now closed. If you have any questions, please contact the host
        {/* To RSVP, please enter the three letter code as it appears on your invitation and enter the number of people attending. */}
      </p>
      {/* <RSVPForm /> */}
    </div>
  </>
);

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <header className="App-header">
            {/* <nav className="navbuttons">
              <button className="navbutton" onClick={() => window.location.href = '/admin'}>Admin</button>
            </nav> */}
          </header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<AdminLogin />} />
            <Route
              path="/admin/dashboard"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <div className = "admin-button">
          <button className="navbutton" onClick={() => window.location.href = '/admin'}>.</button>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
